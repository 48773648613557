<template>
  <div style="margin: 0 auto; max-width: 700px;padding: 10px;">
    <h3 style="text-align: center;">{{$t('All.冲压')}}/{{$t('All.注塑标识卡')}}</h3>
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="160px"
      label-position="right" style="margin-right: 100px;">
      <el-form-item :label="$t('All.工单号')" prop="Gd">
        <el-input v-model="formData.Gd" :placeholder="$t('All.请输入工单号')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.品名')" prop="Product_name">
        <el-input v-model="formData.Product_name" :placeholder="$t('All.请输入品名')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.规格')" prop="Gg">
        <el-input v-model="formData.Gg" :placeholder="$t('All.请输入规格')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.料号')" prop="Item_no">
        <el-input v-model="formData.Item_no" :placeholder="$t('All.请输入料号')" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('All.生产日期')" prop="Cdate">
        <el-date-picker v-model="formData.Cdate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          :style="{width: '100%'}" :placeholder="$t('All.请选择生产日期')" clearable></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('All.入库日期')" prop="Rdate">
        <el-date-picker v-model="formData.Rdate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          :style="{width: '100%'}" :placeholder="$t('All.请选择入库日期')" clearable></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('All.机台')" prop="Jt">
        <el-input v-model="formData.Jt" :placeholder="$t('All.请输入机台')" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="formData.Jt=formData.Jt.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" maxlength=3 clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.单位')" prop="Dw">
        <el-select v-model="formData.Dw" filterable allow-create :placeholder="$t('All.单位')" @change="chuangjcompany" :style="{width: '100%'}">
          <el-option label="G" value="G"></el-option>
          <el-option label="KG" value="KG"></el-option>
          <el-option label="PC" value="PC"></el-option>
          <el-option label="M" value="M"></el-option>
          <el-option label="KM" value="KM"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('All.班别')" prop="Bb" >
        <el-select v-model="formData.Bb" :placeholder="$t('All.请选择')" @change="banbieblur(formData.Bb)" :style="{width: '100%'}">
          <el-option :label="$t('All.白班')" :value="$t('All.白班')"></el-option>
          <el-option :label="$t('All.夜班')" :value="$t('All.夜班')"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('All.Lot号')" prop="Lot">
        <el-input v-model="formData.Lot" :placeholder="$t('All.请输入Lot号')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.生产数量')" prop="Scsl">
        <el-input v-model="formData.Scsl" :placeholder="$t('All.请输入生产数量')" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item
        v-for="(domain, index) in Boxlist"
        :label="$t('All.数量')"
        :key="index"
      >
        <el-input v-model="domain.Boxquantity"  onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" style="width:100px;" autocomplete="off"></el-input>
        <span style="padding: 10px;">{{$t('All.张数')}}:</span><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = null; }" v-model="domain.Box" style="width:100px;" autocomplete="off"></el-input><el-button v-show="index + 1 === Boxlist.length" style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"  @click="addDomainbut()"></el-button><el-button v-show="Boxlist.length > 1" size="mini" type="danger" icon="el-icon-delete"  @click="removeDomainbut(domain)"></el-button>
      </el-form-item>
      <el-form-item :label="$t('All.打印机')">
          <el-select :style="{width: '100%'}" v-model="Printname" value-key="Id" :placeholder="$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item">
          </el-option>
          </el-select>
        </el-form-item>
      <el-form-item size="large" style="text-align: center;">
        <el-button type="primary" @click="submitForm">{{ $t('All.提交') }}</el-button>
        <el-button @click="resetForm">{{ $t('All.重置') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data () {
    var geigd = (rule, value, callback) => {
      this.GetERP(value)
      callback()
    }
    return {
      formData: {
        Product_name: '',
        Serialcode: '',
        Gg: '',
        Item_no: '',
        Lot: '',
        Bb: '',
        Rdate: new Date(),
        Cdate: new Date(),
        Scsl: '',
        DScsl: 0,
        Czu: this.$store.state.Login.Username,
        Czuuser: this.$store.state.Login.User,
        Gd: '',
        Jt: '',
        State: 0,
        Type: 0,
        Fby: '',
        Fbyuser: '',
        Cid: 0,
        Numbers: '',
        Syr: '',
        Syruser: '',
        Dw: 'PC',
        Region: this.$store.state.Login.Factory
      },
      rules: {
        Product_name: [{
          required: true,
          message: this.$t('All.请输入品名'),
          trigger: 'blur'
        }],
        Gg: [{
          required: true,
          message: this.$t('All.请输入规格'),
          trigger: 'blur'
        }],
        Item_no: [{
          required: true,
          message: this.$t('All.请输入料号'),
          trigger: 'blur'
        }],
        Cdate: [{
          required: true,
          message: this.$t('All.请选择生产日期'),
          trigger: 'change'
        }],
        Rdate: [{
          required: true,
          message: this.$t('All.请选择入库日期'),
          trigger: 'change'
        }],
        Lot: [{
          required: true,
          message: this.$t('All.请输入Lot号'),
          trigger: 'blur'
        }],
        Gd: [{
          required: true,
          message: this.$t('All.请输入工单号'),
          trigger: 'blur'
        }, {
          required: true,
          validator: geigd,
          trigger: 'blur'
        }],
        Jt: [{
          required: true,
          message: this.$t('All.请输入机台'),
          trigger: 'blur'
        }],
        Bb: [{
          required: true,
          message: this.$t('All.请输入班别'),
          trigger: 'blur'
        }],
        Scsl: [{
          required: true,
          message: this.$t('All.请输入生产数量'),
          trigger: 'blur'
        }]
      },
      Printname: '',
      Boxlist: [
        {
          Box: 1,
          Boxquantity: 1
        }
      ],
      lotsanma: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.BskCount()
  },
  mounted () {},
  methods: {
    addDomainbut () {
      var zong = 0
      this.Boxlist.forEach(element => {
        zong = this.$PublicJs.add(zong, this.$PublicJs.mul(element.Box, element.Boxquantity))
      })
      var shengyu = this.$PublicJs.sub(this.formData.Scsl, zong)
      if (shengyu <= 0) return this.$message.error(this.$t('All.数量已不足'))
      this.Boxlist.push({
        Box: 1,
        Boxquantity: shengyu
      })
    },
    removeDomainbut (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          if (this.Boxlist.length === 1) {
            return this.$message.error(this.$t('All.最少保留一列'))
          }
          var index = this.Boxlist.indexOf(item)
          if (index !== -1) {
            this.Boxlist.splice(index, 1)
          }
        })
        .catch(() => {
        })
    },
    submitForm () {
      this.formData.DScsl = this.formData.Scsl
      const loadingInstance = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        if (this.Printname.length < 1) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.请选择打印机后在进行打印'))
        }
        this.$refs.elForm.validate(async valid => {
          if (!valid) {
            loadingInstance.close()
            return this.$message.error(this.$t('All.数据填写不完整'))
          }
          var isbool = 0
          this.Boxlist.forEach(element => {
            isbool += parseInt(element.Box)
          })
          if (isbool > 10) {
            loadingInstance.close()
            this.$confirm(`${this.$t('All.确认要打印')}${isbool}${this.$t('All.张')}？`, this.$t('All.提示'), {
              confirmButtonText: this.$t('All.确认'),
              cancelButtonText: this.$t('All.取消'),
              type: 'warning'
            })
              .then(async () => {
                const loadingInstance1 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
                const postdata = {
                  Boxlist: this.Boxlist,
                  CYClass: this.formData,
                  PrinterData: this.Printname,
                  UserData: this.$store.state.Login
                }
                const { data: res } = await this.$http.post('/api/Semifinished/CYSeleUserData', postdata)
                if (res.status !== 200) {
                  loadingInstance1.close()
                  return this.$message.error(res.msg)
                }
                this.BskCount()
                loadingInstance1.close()
                this.$message.success(res.msg)
                this.$refs.elForm.resetFields()
              })
              .catch(() => {
              })
            return
          }
          const loadingInstance2 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
          const postdata = {
            Boxlist: this.Boxlist,
            CYClass: this.formData,
            PrinterData: this.Printname,
            UserData: this.$store.state.Login
          }
          const { data: res } = await this.$http.post('/api/Semifinished/CYSeleUserData', postdata)
          if (res.status !== 200) {
            loadingInstance2.close()
            return this.$message.error(res.msg)
          }
          this.BskCount()
          loadingInstance2.close()
          this.$message.success(res.msg)
          this.$refs.elForm.resetFields()
        })
      } catch (error) {
        loadingInstance.close()
      }
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    },
    async GetERP (value) {
      const { data: res } = await this.$http.get(`api/Semifinished/GetERP?Gongdan=${value}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData.Product_name = res.response.Imdsc1
      this.formData.Gg = res.response.Imdsc2
      this.formData.Item_no = res.response.Walitm
      this.formData.Scsl = res.response.Wauorg
      this.$message.success(this.$t('All.系统检测已帮申请过的信息自动填写'))
    },
    banbieblur (row) {
      var a = this.formData.Jt
      var myDate = new Date(this.formData.Cdate)
      var year = myDate.getFullYear()
      var yy = year.toString().substr(2, year.length)
      a += yy
      a += this.$PublicJs.Bskyuefen(this.formData.Cdate)
      a += myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()

      if (row === this.$t('All.白班')) {
        a += '1'
      } else if (row === this.$t('All.夜班')) {
        a += '2'
      }
      this.formData.Lot = a + this.lotsanma
    },
    async BskCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const QcCountLogin = {
          Login: this.$store.state.Login,
          Type: 'CY'
        }
        const { data: res } = await this.$http.post('/api/Semifinished/BskCount', QcCountLogin)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        this.lotsanma = res.response
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    },
    chuangjcompany () {
      var inputValue = this.formData.Dw
      // 移除非字母字符
      inputValue = inputValue.replace(/[^A-Za-z]/g, '')
      // 转换为大写
      inputValue = inputValue.toUpperCase()
      this.formData.Dw = inputValue
    }
  }
}

</script>
<style>
</style>
